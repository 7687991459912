@import "src/sass/abstracts/variables";

.layout {
  height: 100vh;
  max-width: 1250px;
  min-width: 1000;
  margin: 0 auto;

  

  &_components {
    min-height: calc(100vh - 130px);
  }

  
}
