// font family
$font_family: Helvetica, Arial, sans-serif !important;

// colors
$blue3: #56ccf2;
$green: #64eebc;
$white: #fff;

$grey1: #FBFBFB;
$grey2: #f8f8f8;
$grey3: #4F4F4F;
$grey4: #333333;
$grey5: #828282;
$grey6: #c4c4c4;

$semi-grey: #F4F4F4;
$light-grey: #FBFBFB;
$icon-grey: #C4C4C4;

$semi-dark: #333333;
$dark-text: #1a1a1a;
$border-grey: #828282;
$tasso-red: #632121;
