@mixin font-features {
  font-family: Helvetica, Arial, sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

@mixin flex-x-centered {
  display: flex;
  justify-content: center;
}

@mixin flex-y-centered {
  display: flex;
  align-items: center;
}

@mixin flex-xy-centered {
  @include flex-x-centered;
  align-items: center;
}

@mixin border {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

@mixin border-grey {
  border: 1px solid rgba(196, 196, 196, 0.5);
}

@mixin border-radius {
  box-sizing: border-box;
  border-radius: 5px;
}

@mixin input-border {
  @include border;
  @include border-radius;

  &:hover,
  &:focus,
  &:focus-within {
    border: 1px solid black;
  }
}

@mixin box-border-grey {
  @include border-grey;
  @include border-radius;

  &:hover,
  &:focus,
  &:focus-within {
    border: 1px solid $grey6;
  }
}

@mixin input-border-error {
  @include border-radius;
  border: 1px solid rgba(255, 0, 0, 0.5);

  hr {
    border-top: 1px solid red;
  }

  &:hover,
  &:focus {
    border: 1px solid red;
  }
}

@mixin input-style {
  @include flex-y-centered;
  @include input-border;

  input {
    width: 100%;
    border: none;
    margin-left: 5px;
  }
}

@mixin input-style-error {
  @include flex-y-centered;
  @include input-border-error;

  input {
    width: 100%;
    border: none;
    margin-left: 5px;
  }
}

@mixin box-shadow-1 {
  -webkit-box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.75);
}

@mixin box-shadow-2 {
  -webkit-box-shadow: 3px 3px 5px -1px rgba(196, 196, 196, 0.75);
  -moz-box-shadow: 3px 3px 5px -1px rgba(196, 196, 196, 0.75);
  box-shadow: 3px 3px 5px -1px rgba(196, 196, 196, 0.75);
}
